<template>
  <div class="base-material" v-loading="isLoading">
    <div class="base-content">
      <!-- 标准板材 -->
      <div class="standard-slab">
        <div class="standard_slab_head">
          <div class="standard-slab-title flex flex-cross--center">
            <div class="bluelable"></div>
            <span class="small-title mr10">{{
              $t('common.standardMaterial')
            }}</span>
            <!-- <span>（{{ $t('basePlankManager.saveTip') }}）</span> -->
          </div>
          <!-- <a-button type="primary" @click="save" :disabled="disableSaveBtn"
            ><a-icon type="save" />保存设置</a-button
          > -->
          <div>
            <a-button
              @click="handleDeleteStandard()"
              :class="[
                'standard-slab-btn delete-btn',
                selectStandardList.length ? '' : 'delete-btn-hover',
              ]"
              :disabled="!selectStandardList.length"
            >
              <span class="iconfont el-icon-delete"></span>
              <!-- 删除标准板材 -->
              <span class="inline-block ml4">{{
                $t('basePlankManager.deleteStandardPlate')
              }}</span>
            </a-button>
            <a-button
              @click="handleAddStandard()"
              class="standard-slab-btn add-btn ml10"
            >
              <span class="iconfont icon-add"></span>
              <!-- 新建标准板材 -->
              <span class="inline-block ml4">{{
                $t('basePlankManager.createStandardPlate')
              }}</span>
            </a-button>
          </div>
        </div>
        <div class="standard-table">
          <div class="standard-table-header">
            <el-table
              :data="standardData"
              border
              select-on-indeterminate
              @selection-change="handleStandardSelect"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column
                :label="`${$t('common.plankWidth')}(mm)`"
                prop="plankWidth"
              >
              </el-table-column>
              <!-- 板材长度 -->
              <el-table-column
                :label="`${$t('common.plankHeight')}(mm)`"
                prop="plankHeight"
              >
              </el-table-column>
              <!-- 修边尺寸 -->
              <el-table-column
                :label="`${$t('basePlankManager.offsetSize')}(mm)`"
                prop="plankEdgeOff"
              >
              </el-table-column>
              <!-- 操作 -->
              <el-table-column :label="$t('common.operation')">
                <template #default="{ row, $index }">
                  <div>
                    <!-- 编辑 -->
                    <span
                      style="color: #18a8c7"
                      class="mr12 cursor-pointer"
                      @click="handleEditSta(row, $index)"
                      >{{ $t('common.edit') }}</span
                    >
                    <!-- 删除 -->
                    <span
                      :style="{
                        color: standardData.length > 1 ? '#18a8c7' : '#999',
                      }"
                      class="cursor-pointer"
                      @click="handleDeleteStandard(row)"
                      >{{ $t('common.delete') }}</span
                    >
                  </div>
                </template>
              </el-table-column>
              <!-- 默认选用 -->
              <el-table-column
                :label="$t('basePlankManager.defaultUse')"
                prop="default_selected"
              >
                <template #default="{ row }">
                  <div class="defaultCheck">
                    <el-checkbox
                      :value="row.default_selected"
                      @change="handleChangeStandardSelect(row)"
                    ></el-checkbox>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <!-- 特殊板材 -->
      <div class="special-slab mt20">
        <div class="top-operation">
          <div class="top-operation-left">
            <div class="standard-slab-title">
              <div class="bluelable"></div>
              <span class="small-title ellipsis">{{
                $t('common.specialMaterial')
              }}</span>
              <span
                class="small-title ellipsis"
                style="margin-left: 10px; color: rgba(0, 0, 0, 0.25)"
                >{{ translateLang('basePlankManager.settingRemark') }}</span
              >
            </div>
          </div>
          <div class="top-operation-right">
            <a-input-search
              v-model="searchVal"
              :placeholder="$t('basePlankManager.searchPlaceholder')"
              style="width: 300px"
              id="base_material_specialPlank_searchPlank_input"
            />
            <a-button
              @click="handleOpenSettingModal"
              class="special-slab-btn setting-btn"
              id="base_material_specialPlank_setting_btn"
              :title="translateLang('basePlankManager.addPlank')"
            >
              <div class="flex flex-cross--center flex-main--center">
                <a-icon type="setting" />
                <span style="margin-left: 4px" class="ellipsis">{{
                  $t('main.head.setting')
                }}</span>
              </div>
            </a-button>
            <a-button
              @click="
                isSyncGuigui = true
                fromMc = '柜门'
              "
              v-if="isThinkerxMaterial"
              class="special-slab-btn synchronize-btn"
              id="base_material_specialPlank_sync_guimen_btn"
              :title="
                translateLang('basePlankManager.syncDataTip', {
                  fromMc: translateLang('common.guimen'),
                })
              "
            >
              <div class="flex flex-cross--center">
                <span class="iconfont icon-recover"></span>
                <span
                  style="
                    display: inline-block;
                    margin-left: 4px;
                    width: 84px;
                    line-height: 100%;
                  "
                  class="ellipsis"
                  >{{
                    $t('basePlankManager.syncDataTip', {
                      fromMc: translateLang('common.guimen'),
                    })
                  }}</span
                >
              </div>
            </a-button>
            <a-button
              @click="
                isSyncGuigui = true
                fromMc = ''
              "
              class="special-slab-btn synchronize-btn ellipsis"
              id="base_material_specialPlank_sync_guigui_btn"
              :title="translateLang('basePlankManager.syncData')"
            >
              <div class="flex flex-cross--center">
                <span class="iconfont icon-recover"></span>
                <span
                  style="display: inline-block; margin-left: 4px; width: 84px"
                  class="ellipsis"
                  >{{ $t('basePlankManager.syncData') }}</span
                >
              </div>
            </a-button>
            <a-button
              @click="showDeleteDialog()"
              :class="[
                'special-slab-btn delete-btn',
                selecteds.length ? '' : 'delete-btn-hover',
              ]"
              :disabled="!selecteds.length"
              id="base_material_specialPlank_delete_plank_btn"
              :title="translateLang('basePlankManager.deletePlank')"
            >
              <div class="flex flex-cross--center">
                <span class="iconfont el-icon-delete"></span>
                <span
                  style="display: inline-block; margin-left: 4px; width: 84px"
                  class="ellipsis"
                  >{{ $t('basePlankManager.deletePlank') }}</span
                >
              </div>
            </a-button>
            <a-button
              @click="addNewSlab()"
              class="special-slab-btn add-btn"
              id="base_material_specialPlank_add_plank_btn"
              :title="translateLang('basePlankManager.addPlank')"
            >
              <div class="flex flex-cross--center">
                <span class="iconfont icon-add"></span>
                <span
                  style="display: inline-block; margin-left: 4px; width: 84px"
                  class="ellipsis"
                  >{{ $t('basePlankManager.addPlank') }}</span
                >
              </div>
            </a-button>
          </div>
        </div>
        <div class="special-slab-list">
          <el-table
            :data="filterSlabList"
            border
            :header-cell-style="{ backgroundColor: '#f5f5f5' }"
            :header-cell-class-name="({ column }) => column.property"
            row-key="rowId"
            default-expand-all
            @selection-change="handleSelectionChange"
            select-on-indeterminate
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            :row-class-name="tableRowClassName"
            :empty-text="translateLang('historyPage.trailProduction.empty')"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              prop="matCode"
              :label="$t('common.plankMatCode')"
              width="180"
            >
              <template slot="header">
                <div class="flex flex-main--justify flex-cross--center">
                  <span>{{ $t('common.plankMatCode') }}</span>
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">
                      {{ $t('basePlankManager.unessential') }}<br />{{
                        $t('basePlankManager.emptyTip')
                      }}
                    </div>
                    <img
                      src="@/assets/icon/questionMark.png"
                      alt=""
                      style="width: 17px; height: 17px"
                    />
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="color"
              :label="$t('common.plankColor')"
              width="180"
            >
              <template slot="header">
                <div class="flex flex-main--justify flex-cross--center">
                  <span>{{ $t('common.plankColor') }}</span>
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">
                      {{ $t('basePlankManager.unessential') }}<br />{{
                        $t('basePlankManager.emptyTip')
                      }}
                    </div>
                    <img
                      src="@/assets/icon/questionMark.png"
                      alt=""
                      style="width: 17px; height: 17px"
                    />
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="thick"
              :label="`${$t('common.plankThick')}(mm)`"
            >
              <template slot="header">
                <div class="flex flex-main--justify flex-cross--center">
                  <span>{{ $t('common.plankThick') }}(mm)</span>
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content">
                      {{ $t('basePlankManager.unessential') }}<br />{{
                        $t('basePlankManager.emptyTip')
                      }}
                    </div>
                    <img
                      src="@/assets/icon/questionMark.png"
                      alt=""
                      style="width: 17px; height: 17px"
                    />
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="width"
              :label="`${$t('common.plankWidth')}(mm)`"
            >
              <template slot-scope="scope">
                <div
                  :id="`base_material_specialPlank_${scope.row.rowId}_width`"
                >
                  {{ scope.row.children ? '-' : scope.row.width }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="height"
              :label="`${$t('common.plankHeight')}(mm)`"
            >
              <template slot-scope="scope">
                <div
                  :id="`base_material_specialPlank_${scope.row.rowId}_height`"
                >
                  {{ scope.row.children ? '-' : scope.row.height }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="trim_edge"
              :label="translateLang('materialPage.paibanSetting.plankEdgeOff')"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.children ? '-' : scope.row.trim_edge }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="is_high_gloss_plank"
              :label="$t('common.heighGlossPlank')"
              style="color: red"
            >
              <template #header>
                {{ $t('common.heighGlossPlank') }}
              </template>
              <template slot-scope="scope">
                <aSelect
                  v-if="!scope.row.children"
                  class="w100"
                  v-model="scope.row.is_high_gloss_plank"
                  dropdownMatchSelectWidth
                  @change="changePlankType($event, scope.row)"
                  :id="`base_material_specialPlank_${scope.row.rowId}_gloss_select`"
                >
                  <a-select-option
                    v-for="(option, index) in [
                      { value: 0, label: $t('common.notHeighGlossPlank') },
                      { value: 1, label: $t('common.heighGlossPlank') },
                    ]"
                    :value="option.value"
                    :key="option.value"
                    :id="`base_material_specialPlank_${scope.row.rowId}_gloss_select_${index}`"
                    >{{ option.label }}</a-select-option
                  >
                </aSelect>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.operation')">
              <template slot-scope="scope">
                <div>
                  <span
                    :style="{
                      cursor: 'pointer',
                      color: !(scope.row.children || scope.row.plateId)
                        ? '#18A8C7'
                        : '#999',
                    }"
                    @click="showEditSlab(scope.row)"
                    class="mr12"
                    :id="`base_material_specialPlank_${scope.row.index}_edit_btn`"
                    >{{ $t('common.edit') }}</span
                  >
                  <span
                    style="color: #18a8c7; cursor: pointer"
                    @click="showDeleteDialog(scope.row)"
                    :id="`base_material_specialPlank_${scope.row.index}_delete_btn`"
                    >{{ $t('common.delete') }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('basePlankManager.defaultUse')">
              <template slot-scope="scope" v-if="!scope.row.children">
                <div class="defaultCheck">
                  <el-checkbox
                    v-model="scope.row.isPicked"
                    @change="changePicked(scope.row)"
                  ></el-checkbox>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 代码保留，后续可能会重新做 -->
          <!-- <specialPlankTable
            :data="filterSlabList"
            :mat-code-list="matcodeList"
            :color-list="colorList"
          ></specialPlankTable> -->
        </div>
      </div>
      <!-- 原片下料选择 -->
      <div class="cutting-select mt20">
        <div class="standard_slab_head">
          <div class="standard-slab-title">
            <div class="bluelable"></div>
            <span class="small-title mr10">{{
              $t('basePlankManager.plankKnifeSelect')
            }}</span>
            <!-- 取消内嵌排版提示 -->
            <!-- <span class="cutting-tip"
              >（{{ $t('basePlankManager.knifeSelectTip') }}）</span
            > -->
          </div>
          <el-button
            icon="el-icon-plus"
            @click="createBlankData"
            size="small"
            type="primary"
            :disabled="isAddHandle"
            :id="`base_material_specialPlank_create_btn`"
            >{{ $t('common.add') }}</el-button
          >
        </div>
        <div class="special-slab-list">
          <el-table
            border
            class="blank-table"
            :header-cell-style="{ backgroundColor: '#f5f5f5' }"
            :data="blankList"
            :cell-class-name="blankCellClassName"
            :cell-style="blankSelectedRCellStyle"
            @cell-click="blankCellClick"
            :empty-text="translateLang('historyPage.trailProduction.empty')"
          >
            <el-table-column
              prop="matCode"
              :label="translateLang('common.plankMatCode')"
            >
              <template #default="{ row, $index }">
                <div
                  v-if="row.isShowSaveBtn"
                  style="height: 100%"
                  class="ml10 cursor-pointer"
                  @click="showBlankInput($index, 'matCode')"
                  :id="`base_material_plank_table_${$index}_matCode`"
                >
                  {{ row.matCode }}
                </div>
                <el-input
                  v-else
                  :placeholder="`${$t('basePlankManager.matcodeInput')}`"
                  :ref="`matCode${$index}`"
                  v-model="row.matCode"
                  class="custom-style"
                  maxlength="12"
                  show-word-limit
                  @focus="blankFocus($index)"
                  @input="
                    (event) => handleBlankInput(event, row, 'matCode', $index)
                  "
                  @blur="handleBlankBlur(row, 'matCode', $index)"
                  :id="`base_material_plank_table_${$index}_matCode_input`"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="thick"
              :label="`${translateLang('common.plankThick')}(mm)`"
            >
              <template #default="{ row, $index }">
                <div
                  v-if="row.isShowSaveBtn"
                  class="ml10 cursor-pointer"
                  style="height: 100%"
                  @click="showBlankInput($index, 'thick')"
                  :id="`base_material_plank_table_${$index}_thick`"
                >
                  {{ row.thick }}
                </div>
                <el-input
                  v-else
                  :placeholder="$t('basePlankManager.thickInput')"
                  :ref="`thick${$index}`"
                  v-model="row.thick"
                  class="custom-style"
                  @focus="blankFocus($index)"
                  @input="
                    (event) => handleBlankInput(event, row, 'thick', $index)
                  "
                  @blur="handleBlankBlur(row, 'thick', $index)"
                  :id="`base_material_plank_table_${$index}_thick_input`"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="blankTool"
              :label="translateLang('basePlankManager.knife')"
            >
              <template #default="{ row, $index }">
                <el-select
                  v-model="row.knife"
                  class="custom-style"
                  @focus="blankFocus($index)"
                  @change="handleSelectKnife"
                  :id="`base_material_plank_table_${$index}_knife_select`"
                >
                  <el-option
                    v-for="(item, index) in blankSelectOptins"
                    :key="index"
                    :value="item.value"
                    :label="item.label"
                    :id="`base_material_plank_table_${$index}_knife_select_option_${index}`"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.operation')">
              <template #default="{ row, $index }">
                <div>
                  <el-button
                    type="primary"
                    size="mini"
                    v-show="!row.isShowSaveBtn"
                    class="custom-button"
                    @click="confirmOrEditBlankItem($index)"
                    :disabled="
                      !(row.matCode && row.thick && row.thick - 0 != 0)
                    "
                    :id="`base_material_plank_table_${$index}_oprate_save_btn`"
                    >{{ $t('common.save') }}</el-button
                  >
                  <el-button
                    type="text"
                    v-show="row.isShowCancelBtn"
                    @click="handleCancelBlank($index)"
                    :id="`base_material_plank_table_${$index}_oprate_cancel_btn`"
                    >{{ $t('common.cancel') }}</el-button
                  >
                  <el-button
                    type="text"
                    @click="deleteBlankData($index)"
                    v-show="row.id"
                    :id="`base_material_plank_table_${$index}_oprate_delete_btn`"
                    >{{ $t('common.delete') }}
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 新建/编辑标准板材弹窗 -->
    <m-plank-drawer
      :show-visible="showStandardDrawer"
      :title="drawerTitle"
      :isCreateNewSlab="isCreateNewSlab"
      :drawer-data="drawerData"
      :copy-drawer-data="copyDrawerData"
      :standardData="standardData"
      :standard-row-index="standardRowIndex"
      :inputAlert="standardInputAlert"
      @onCloseDrawer="handleCloseDrawer"
      @onSave="handleSaveDrawer"
    ></m-plank-drawer>

    <!-- 新建板材弹窗 -->
    <el-drawer
      :visible.sync="showNewDrawer"
      direction="rtl"
      :size="500"
      :show-close="false"
      :close-on-press-escape="false"
      @close="clearNewPlankInfo"
    >
      <div slot="title" class="drawer-title">
        <span>{{ $t('basePlankManager.addPlank') }}</span>
        <span
          class="iconfont icon-close"
          id="base_material_specialPlank_create_plank_close_btn"
          @click="closeNewDrawer"
        ></span>
      </div>
      <div class="drawer-main">
        <div class="new-plank-form h100">
          <div class="new-plank-form-item">
            <span class="plank-form-title"
              >{{ $t('common.plankMatCode') }}：
            </span>
            <!-- <div v-if="isThinkerxMaterial">
              <el-input
                v-model="newSlab.matCode"
                @input="inputCheck('matCode')"
                @blur="inputCheck('matCode')"
                id="base_material_specialPlank_create_plank_matcode_input"
                :placeholder="$t('basePlankManager.matcodeInput')"
              ></el-input>
            </div> -->
            <div class="plank-form-input">
              <el-select
                v-model="newSlab.matCode"
                filterable
                clearable
                @input="inputCheck('matCode')"
                @blur="inputCheck('matCode')"
                @keydown.native.enter="handleEnterMatCode"
                @change="handleSelectMatCode"
                size="small"
                id="base_material_specialPlank_create_plank_matcode_select"
                :placeholder="$t('basePlankManager.matcodeInput')"
              >
                <el-option
                  v-for="(item, index) in matcodeList"
                  :key="'type' + index"
                  :value="item.value"
                  :label="item.label"
                  :id="`base_material_specialPlank_create_plank_matcode__select_${index}`"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="new-plank-form-item">
            <span class="plank-form-title"
              >{{ $t('common.plankColor') }}：
            </span>
            <el-cascader
              v-loading="isLoadingColors"
              id="base_material_specialPlank_create_plank_color_input"
              size="small"
              v-model="newPlankInfoTexture"
              :options="materialColorOptions"
              @change="handleSelectTexture"
              @keydown.enter.native="handleEnterColor"
              @keyup.delete.native="handleDeleteColorSearch"
              ref="addColorCascaderRef"
              :before-filter="onBeforeFilter"
              :show-all-levels="false"
              filterable
              clearable
              :placeholder="translateLang('common.colorPlaceHolder')"
            />
            <!-- <div v-if="isThinkerxMaterial">
              <el-input
                v-model="newSlab.color"
                @input="inputCheck('color')"
                @blur="inputCheck('color')"
                placeholder="请输入颜色"
                id="base_material_specialPlank_create_plank_color_input"
              ></el-input>
            </div>
            <div class="plank-form-input relative colorInpWrap" v-else>
              <el-cascader
                :options="colorList"
                v-model="newSlab.color"
                size="small"
                @change="(e) => cascaderBlurOrChange(e, 'change')"
                @blur="(e) => cascaderBlurOrChange(e, 'blur')"
                @visible-change="colorEnterHandle"
                placeholder="试试搜索：T01"
                filterable
                :show-all-levels="false"
                ref="colorCascaderRef"
              ></el-cascader>

              <el-input
                class="absolute"
                placeholder="试试搜索：T01"
                v-model="inputColorValue"
                @focus="colorInpFocus"
                v-if="showCascaderOrInp === 'input'"
                suffix-icon="el-icon-arrow-down"
              ></el-input>
            </div> -->
          </div>
          <div class="new-plank-form-item">
            <span class="plank-form-title"
              >{{ $t('common.plankThick') }}：
            </span>
            <div v-if="isThinkerxMaterial">
              <el-input
                v-model="newSlab.thick"
                @input="inputCheck('thick')"
                @blur="inputCheck('thick')"
                :placeholder="translateLang('basePlankManager.thickInput')"
              ></el-input>
              <transition name="errorInfo">
                <span v-show="inputAlert.thickInputAlert" class="error-info">
                  {{ inputAlert.thickInputAlert }}
                </span>
              </transition>
            </div>
            <div class="plank-form-input" v-else>
              <el-select
                v-model="newSlab.thick"
                filterable
                allow-create
                default-first-option
                @input="inputCheck('thick')"
                @blur="inputCheck('thick')"
                size="small"
                id="base_material_specialPlank_create_plank_thick_select"
                :placeholder="
                  translateLang('basePlankManager.thickPlaceHolder')
                "
              >
                <el-option
                  v-for="(item, index) in thickList"
                  :key="'type' + index"
                  :value="item.value"
                  :label="item.label"
                  :id="`base_material_specialPlank_create_plank_thick_select_${index}`"
                ></el-option>
              </el-select>
              <transition name="errorInfo">
                <span v-show="inputAlert.thickInputAlert" class="error-info">
                  {{ inputAlert.thickInputAlert }}
                </span>
              </transition>
            </div>
          </div>
          <div class="new-plank-form-item">
            <span class="plank-form-title">
              <span class="important-icon">*</span
              >{{ $t('common.plankWidth') }}：
            </span>
            <div class="plank-form-input">
              <a-input
                v-model="newSlab.width"
                @input="inputCheck('width')"
                @blur="inputCheck('width')"
                :class="inputAlert.slabWidthInputAlert ? 'alert-input' : ''"
                id="base_material_specialPlank_create_plank_width_input"
              >
                <span slot="addonAfter" class="plank-form-after">mm</span>
              </a-input>
              <transition name="errorInfo">
                <span
                  v-show="inputAlert.slabWidthInputAlert"
                  class="error-info"
                  >{{ inputAlert.slabWidthInputAlert }}</span
                >
              </transition>
            </div>
          </div>
          <div class="new-plank-form-item">
            <span class="plank-form-title">
              <span class="important-icon">*</span
              >{{ $t('common.plankHeight') }}：
            </span>
            <div class="plank-form-input">
              <a-input
                v-model="newSlab.height"
                @input="inputCheck('height')"
                @blur="inputCheck('height')"
                :class="inputAlert.slabLengthInputAlert ? 'alert-input' : ''"
                id="base_material_specialPlank_create_plank_long_input"
              >
                <span slot="addonAfter" class="plank-form-after">mm</span>
              </a-input>
              <transition name="errorInfo">
                <span
                  v-show="inputAlert.slabLengthInputAlert"
                  class="error-info"
                  >{{ inputAlert.slabLengthInputAlert }}</span
                >
              </transition>
            </div>
          </div>
          <div class="new-plank-form-item">
            <span class="plank-form-title">
              <span class="important-icon">*</span
              >{{ $t('materialPage.paibanSetting.plankEdgeOff') }}：
            </span>
            <div class="plank-form-input">
              <a-input
                v-model="newSlab.trim_edge"
                @input="inputCheck('trim_edge')"
                @blur="inputCheck('trim_edge')"
                :class="inputAlert.trimEdgeInputAlert ? 'alert-input' : ''"
              >
                <span slot="addonAfter" class="plank-form-after">mm</span>
              </a-input>
              <transition name="errorInfo">
                <span
                  v-show="inputAlert.trimEdgeInputAlert"
                  class="error-info"
                  >{{ inputAlert.trimEdgeInputAlert }}</span
                >
              </transition>
            </div>
          </div>
          <div class="new-plank-form-item">
            <span class="plank-form-title">
              <span class="important-icon">*</span
              >{{ $t('common.heighGlossPlank') }}：
            </span>
            <div class="plank-form-input">
              <el-select
                v-model="newSlab.is_high_gloss_plank"
                size="small"
                id="base_material_specialPlank_create_plank_sloss_select"
                :placeholder="translateLang('common.isHeightGloss')"
              >
                <el-option
                  v-for="(item, index) in [
                    {
                      value: 0,
                      label: translateLang('common.notHeighGlossPlank'),
                    },
                    {
                      value: 1,
                      label: translateLang('common.heighGlossPlank'),
                    },
                  ]"
                  :key="index"
                  :value="item.value"
                  :label="item.label"
                  :id="`base_material_specialPlank_create_plank_sloss_select_${index}`"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="drawer-bottom">
          <span
            class="drawer-bottom-btn"
            @click="closeNewDrawer"
            id="base_material_specialPlank_create_plank_cancel_btn"
            >{{ $t('common.cancel') }}</span
          >
          <span
            class="drawer-bottom-btn"
            @click="addNewRowContinue"
            id="base_material_specialPlank_create_plank_saveAndCon_btn"
            >{{ $t('common.saveAndContinue') }}</span
          >
          <span
            class="drawer-bottom-btn confirm-btn"
            @click="saveNewSlab(false)"
            id="base_material_specialPlank_create_plank_save_btn"
            >{{ $t('common.save') }}</span
          >
        </div>
      </div>
    </el-drawer>
    <!-- 编辑板材弹窗 -->
    <el-drawer
      :visible.sync="showEditDrawer"
      direction="rtl"
      @close="closeEditDrawer"
      :size="500"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div slot="title" class="drawer-title">
        <span>{{ $t('basePlankManager.editMatcode') }}</span>
        <span
          class="iconfont icon-close"
          @click="closeEditDrawer"
          id="base_material_specialPlank_edit_plank_close_btn"
        ></span>
      </div>
      <div class="drawer-main">
        <div class="new-plank-form">
          <div class="new-plank-form-item">
            <span class="plank-form-title"
              >{{ $t('common.plankMatCode') }}：
            </span>
            <!-- <div v-if="isThinkerxMaterial">
              <el-input
                v-model="newSlab.matCode"
                @input="inputCheck('matCode')"
                @blur="inputCheck('matCode')"
                id="base_material_specialPlank_edit_plank_matcode_btn"
                :placeholder="translateLang('basePlankManager.matcodeInput')"
              ></el-input>
            </div> -->
            <div class="plank-form-input">
              <el-select
                v-model="newSlab.matCode"
                filterable
                clearable
                @keydown.native.enter="handleEnterMatCode"
                @change="handleSelectMatCode"
                size="small"
                id="base_material_specialPlank_edit_plank_matcode_select"
                :placeholder="
                  translateLang('basePlankManager.matCodePlaceholder')
                "
              >
                <el-option
                  v-for="(item, index) in matcodeList"
                  :key="'type' + index"
                  :value="item.value"
                  :label="item.label"
                  :id="`base_material_specialPlank_edit_plank_matcode_select_${index}`"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="new-plank-form-item">
            <span class="plank-form-title"
              >{{ $t('common.plankColor') }}：
            </span>
            <el-cascader
              v-loading="isLoadingColors"
              id="base_material_specialPlank_edit_plank_color_input"
              size="small"
              v-model="newPlankInfoTexture"
              :options="materialColorOptions"
              @change="handleSelectTexture"
              @keydown.enter.native="handleEnterColor"
              @keyup.delete.native="handleDeleteColorSearch('edit')"
              ref="editColorCascaderRef"
              :before-filter="onBeforeFilter"
              :show-all-levels="false"
              filterable
              clearable
              :placeholder="translateLang('common.colorPlaceHolder')"
            />
            <!-- <div v-if="isThinkerxMaterial">
              <el-input
                v-model="newSlab.color"
                @input="inputCheck('color')"
                @blur="inputCheck('color')"
                placeholder="请输入颜色"
                id="base_material_specialPlank_edit_plank_color_input"
              ></el-input>
            </div>
            <div class="plank-form-input relative colorInpWrap" v-else>
              <el-cascader
                v-if="showEditDrawer"
                :options="colorList"
                v-model="newSlab.color"
                size="small"
                @change="(e) => cascaderBlurOrChange(e, 'change')"
                @blur="(e) => cascaderBlurOrChange(e, 'blur')"
                @visible-change="colorEnterHandle"
                placeholder="试试搜索：T01"
                filterable
                :show-all-levels="false"
                ref="colorCascaderRef"
                id="base_material_specialPlank_edit_plank_color_cascader"
              ></el-cascader>

              <el-input
                class="absolute"
                placeholder="试试搜索：T01"
                v-model="inputColorValue"
                @focus="colorInpFocus"
                v-if="showCascaderOrInp === 'input'"
                suffix-icon="el-icon-arrow-down"
              ></el-input>
            </div> -->
          </div>
          <div class="new-plank-form-item">
            <span class="plank-form-title"
              >{{ $t('common.plankThick') }}：
            </span>
            <div v-if="isThinkerxMaterial">
              <el-input
                v-model="newSlab.thick"
                @input="inputCheck('thick')"
                @blur="inputCheck('thick')"
                id="base_material_specialPlank_edit_plank_thick_input"
                :placeholder="$t('basePlankManager.thickInput')"
              ></el-input>
              <transition name="errorInfo">
                <span v-show="inputAlert.thickInputAlert" class="error-info">
                  {{ inputAlert.thickInputAlert }}
                </span>
              </transition>
            </div>
            <div class="plank-form-input" v-else>
              <el-select
                v-model="newSlab.thick"
                filterable
                allow-create
                default-first-option
                @input="inputCheck('thick')"
                size="small"
                id="base_material_specialPlank_edit_plank_thick_select"
                :placeholder="
                  translateLang('basePlankManager.thickPlaceHolder')
                "
              >
                <el-option
                  v-for="(item, index) in thickList"
                  :key="'type' + index"
                  :value="item.value"
                  :label="item.label"
                  :id="`base_material_specialPlank_edit_plank_thick_select_${index}`"
                ></el-option>
              </el-select>
              <transition name="errorInfo">
                <span v-show="inputAlert.thickInputAlert" class="error-info">
                  {{ inputAlert.thickInputAlert }}
                </span>
              </transition>
            </div>
          </div>
          <div class="new-plank-form-item">
            <span class="plank-form-title">
              <span class="important-icon">*</span
              >{{ $t('common.plankWidth') }}：
            </span>
            <a-input
              v-model="newSlab.width"
              :placeholder="translateLang('common.inputPlaceholder')"
              :class="inputAlert.slabWidthInputAlert ? 'alert-input' : ''"
              @input="inputCheck('width')"
              @blur="inputCheck('width')"
              id="base_material_specialPlank_edit_plank_width_input"
            >
              <span slot="addonAfter" class="plank-form-after">mm</span>
            </a-input>
            <transition name="errorInfo">
              <span
                v-show="inputAlert.slabWidthInputAlert"
                class="error-info"
                >{{ inputAlert.slabWidthInputAlert }}</span
              >
            </transition>
          </div>
          <div class="new-plank-form-item">
            <span class="plank-form-title">
              <span class="important-icon">*</span
              >{{ $t('common.plankHeight') }}：
            </span>
            <a-input
              v-model="newSlab.height"
              :placeholder="translateLang('common.inputPlaceholder')"
              :class="inputAlert.slabLengthInputAlert ? 'alert-input' : ''"
              @input="inputCheck('height')"
              @blur="inputCheck('height')"
              id="base_material_specialPlank_edit_plank_height_input"
            >
              <span slot="addonAfter" class="plank-form-after">mm</span>
            </a-input>
            <transition name="errorInfo">
              <span
                v-show="inputAlert.slabLengthInputAlert"
                class="error-info"
                >{{ inputAlert.slabLengthInputAlert }}</span
              >
            </transition>
          </div>
          <div class="new-plank-form-item">
            <span class="plank-form-title">
              <span class="important-icon">*</span
              >{{ $t('materialPage.paibanSetting.plankEdgeOff') }}：
            </span>
            <div class="plank-form-input">
              <a-input
                v-model="newSlab.trim_edge"
                @input="inputCheck('trim_edge')"
                @blur="inputCheck('trim_edge')"
                :class="inputAlert.trimEdgeInputAlert ? 'alert-input' : ''"
              >
                <span slot="addonAfter" class="plank-form-after">mm</span>
              </a-input>
              <transition name="errorInfo">
                <span
                  v-show="inputAlert.trimEdgeInputAlert"
                  class="error-info"
                  >{{ inputAlert.trimEdgeInputAlert }}</span
                >
              </transition>
            </div>
          </div>
          <div class="new-plank-form-item">
            <span class="plank-form-title">
              <span class="important-icon">*</span>高光板：
            </span>
            <div class="plank-form-input">
              <el-select
                v-model="newSlab.is_high_gloss_plank"
                size="small"
                id="base_material_specialPlank_edit_plank_gloss_select"
                :placeholder="translateLang('common.isHeightGloss')"
              >
                <el-option
                  v-for="(item, index) in [
                    {
                      value: 0,
                      label: translateLang('common.notHeighGlossPlank'),
                    },
                    {
                      value: 1,
                      label: translateLang('common.heighGlossPlank'),
                    },
                  ]"
                  :key="index"
                  :value="item.value"
                  :label="item.label"
                  :id="`base_material_specialPlank_edit_plank_gloss_select_${index}`"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="drawer-bottom">
          <span
            class="drawer-bottom-btn"
            @click="closeEditDrawer"
            id="base_material_specialPlank_edit_plank_cancel_btn"
            >{{ $t('common.cancel') }}</span
          >
          <span
            class="drawer-bottom-btn confirm-btn"
            @click="confirmEditSlab"
            id="base_material_specialPlank_edit_plank_save_btn"
            >{{ $t('common.save') }}</span
          >
        </div>
      </div>
    </el-drawer>
    <!-- 是否同步柜柜数据弹窗 -->
    <div
      class="delete-dialog"
      v-if="isSyncGuigui"
      @click="isSyncGuigui = false"
    >
      <div class="delete-dialog-content" @click.stop>
        <div class="delete-title">
          <span class="iconfont icon-warn"></span>
          <span class="warm-notice">{{ $t('printPage.deleteWarning') }}</span>
        </div>
        <div class="confirm-notice">
          <span>
            {{
              $t('basePlankManager.syncTip', {
                fromMc: fromMc ? fromMc : '柜柜',
              })
            }}
          </span>
        </div>
        <div class="delete-btns">
          <div>
            <a-button
              @click="isSyncGuigui = false"
              class="mr12"
              id="base_material_specialPlank_sync_plank_no_btn"
              ><div class="flex flex-cross--center">
                <span class="ellipsis">{{ $t('common.noSync') }}</span>
              </div></a-button
            >
            <a-button
              class="confirm-btn"
              @click="confirmSyncGuigui(fromMc)"
              id="base_material_specialPlank_sync_plank_yes_btn"
              ><div class="flex flex-cross--center">
                <span class="ellipsis">{{ $t('common.sync') }}</span>
              </div></a-button
            >
          </div>
        </div>
      </div>
    </div>
    <g-base-modal
      :visible="isDeleteStandard"
      v-if="isDeleteStandard"
      @ok="confirmDeleteStandard"
      @cancel="cancelDeleteStandard"
      ok-text="确认删除"
    >
    </g-base-modal>
    <g-base-modal
      :visible="isVisible"
      v-if="isVisible"
      @ok="confirmDeleteBlankData"
      @cancel="cancelDeleteBlankData"
      preffix="delete_plank"
      :ok-text="translateLang('materialPage.delete.confirm')"
    >
    </g-base-modal>
    <g-base-modal
      :visible="isShowDeleteDialog"
      v-if="isShowDeleteDialog"
      @ok="confirmDeleteSlab"
      @cancel="isShowDeleteDialog = false"
      preffix="delete_slab"
      :ok-text="translateLang('materialPage.delete.confirm')"
    >
    </g-base-modal>

    <!-- 设置项modal -->
    <MBaseMaterialSettingModal
      v-if="isShowSettingModal"
      :visible.sync="isShowSettingModal"
      :form-data="settingData"
      @success="handleSettingSuccess"
    />
  </div>
</template>
<script>
import {
  createBlankTableList,
  deleteBlankTableList,
  editBlankTableList,
  getBlankTableList,
} from '@/apis/baseMaterial/blankTable'
import {
  getBasePlank,
  updateBasePlank,
} from '@/apis/baseMaterial/blankTable/index'
import { requestWithDualProtocol } from '@/apis/common'
import { buryPoint } from '@/apis/common/buryPoint'
import GBaseModal from '@/components/g-base-modal.vue'
import newContent from '@/components/newContent.vue'
import { buryPointPayloadMap } from '@/constants/buryPointMap'
import { buryPointFunc } from '@/util/buryPointDeal'
import {
  checkColorIsExist,
  checkMatCodeIsExist,
  fetchMaterialMatCodes,
  translate,
} from '@/util/commonFun'
import {
  buryPointApi,
  genFinalRecord,
  genUniId,
  verifyInputNumber,
} from '@/util/commonFuncs'
import { operateRecordTableType, webId } from '@/util/configure'
import { baseMaterialFormTextObj } from '@/util/operateRecordConfigure/baseMaterialConfigure'
import {
  regReplaceBaseMaterialDealDecimal,
  regReplaceBaseMaterialDefaultInputCheck,
} from '@/util/regReplace'
import { saveDataJsonToOss } from '@/util/saveFile'
import { SettingOutlined } from 'ant-design-vue'
import axios from 'axios'
import { mapMutations, mapState } from 'vuex'

import MBaseMaterialSettingModal from './components/m-baseMaterial-setting-modal.vue'
import mPlankDrawer from './components/m-plank-drawer.vue'

export default {
  components: {
    newContent,
    GBaseModal,
    mPlankDrawer,
    MBaseMaterialSettingModal,
  },
  data() {
    return {
      isAddHandle: false,
      // 新增下料刀具表单
      blankFormData: {
        matCode: '',
        thick: '',
        blankTool: '',
      },
      oriBlankList: [],
      fromMc: '',
      // 是否为第三方数据
      isThinkerxMaterial: false,
      isSyncGuigui: false,
      //记录所有的板材类型的分类
      matcodeList: [],
      // 记录所有的板材颜色
      colorList: [],
      testColor: [],
      thickList: [
        { lable: '', value: '空' },
        { lable: 5, value: 5 },
        { lable: 9, value: 9 },
        { lable: 12, value: 12 },
        { lable: 18, value: 18 },
        { lable: 25, value: 25 },
        { lable: 30, value: 30 },
        { lable: 50, value: 50 },
      ],
      //是否全选板件
      isSelectAll: false,
      //默认板材列表
      standardData: [],
      selectStandardList: [],
      filterStandardList: [],
      showStandardDrawer: false,
      drawerData: {},
      copyDrawerData: {},
      drawerTitle: translate('basePlankManager.createStandardPlate'),
      standardRowIndex: null,
      standardInputAlert: {
        plankWidth: '',
        plankHeight: '',
        plankEdgeOff: '',
      },
      isDeleteStandard: false,
      oriStandardData: [],
      //特殊板材表格
      slabList: [],
      // 记录各种输入警示
      inputAlert: {
        typeInputAlert: '',
        colorInputAlert: '',
        thickInputAlert: '',
        slabLengthInputAlert: '',
        slabWidthInputAlert: '', // 板材宽度
        trimEdgeInputAlert: '',
      },
      //显示删除弹窗
      isShowDeleteDialog: false,
      // 显示添加板件右侧抽屉
      showNewDrawer: false,
      //显示编辑板材右侧抽屉
      showEditDrawer: false,
      //记录选中的板件
      selectionPlankList: [],
      // 记录当前编辑的余料
      currentSurplus: {},
      // 删除弹窗
      isVisible: false,
      // 记录新增的板件对象
      newSlab: {
        matCode: '',
        color: '',
        thick: '',
        width: '',
        height: '',
        is_high_gloss_plank: 0,
      },
      //自动选用更大尺寸规格大板进行排版
      isAutoSelected: false,
      // 特殊大板上只排超尺小板
      onlyLayoutTooBig: false,
      // 排版时自动添加板材提醒
      auto_add_special_plate: false,
      rowIndex: 0,
      deleteBaseItems: [],
      recordId: 0,
      baseList: [],
      oriList: [],
      deleteList: [],
      selecteds: [],
      selectionList: [],
      searchVal: '',
      saveType: '',
      disableSaveBtn: false,
      // 板材颜色 cascader || input
      showCascaderOrInp: 'input',
      // todo
      blankList: [],
      oldBlankList: [],
      // todo
      blankSelectOptins: [],
      // 下料刀的列索引
      blankTableRow: null,
      isRepeat: null, //校验下料刀选择是否重复
      inputColorValue: '', //板材颜色值
      inputEl: null, //板材颜色input的DOM
      isColorEnterEvent: false, //是否为颜色输入框回车事件
      isShowStandardEditInput: {
        plankHeight: false,
        plankWidth: false,
        plankEdgeOff: false,
      },
      materialColorOptions: [],
      newPlankInfoTexture: '',
      colorSearchKey: '',
      newSlabColor: '',
      copyNewSlab: {},
      isChangePicked: false,
      isLoading: false,
      // 新建特殊板材或者修改
      isCreateNewSlab: false,
      // 展示设置模态框
      isShowSettingModal: false,
      // 设置页数据
      settingData: {},
    }
  },
  computed: {
    ...mapState([
      'userInfo',
      'materialColorList',
      'isLoadingColors',
      'baseMaterialColorList',
      'baseMaterialMatCode',
    ]),
    notAbleDelete() {
      let flag = this.slabList.some((v) => v.isSelected)
      if (flag) {
        return false
      } else {
        return true
      }
    },
    filterSlabList() {
      if (!this.searchVal) {
        return this.slabList.map((item) => ({
          ...item,
          matCode: item.matCode.replace(/高光_/g, ''),
          children: item.children?.map((it) => {
            return {
              ...it,
              matCode: it.matCode.replace(/高光_/g, ''),
            }
          }),
        }))
      } else {
        return this.slabList
          .filter(
            (item) =>
              item.matCode.includes(this.searchVal) ||
              item.color.includes(this.searchVal)
          )
          .map((item) => ({
            ...item,
            matCode: item.matCode.replace(/高光_/g, ''),
            children: item.children?.map((it) => {
              return {
                ...it,
                matCode: it.matCode.replace(/高光_/g, ''),
              }
            }),
          }))
      }
    },
  },
  mounted() {
    if (sessionStorage.getItem('thinkerx_material')) {
      this.isThinkerxMaterial = true
    }
    this.getSlabList()
    this.getPlankMatCode()
    this.getColor()
    this.initBlankSelectOptins()
    if (!this.materialColorOptions.length) {
      this.materialColorOptions = this.materialColorList
    }
  },
  methods: {
    ...mapMutations(['setBaseMaterialColorList', 'setBaseMaterialMatCode']),
    // 新建标准板材
    handleAddStandard() {
      buryPointApi('base_material', 'addStandardPlank')
      this.showStandardDrawer = true
      this.drawerTitle = translate('basePlankManager.createStandardPlate')
      this.isCreateNewSlab = true
      this.drawerData = {
        plankWidth: '',
        plankHeight: '',
        plankEdgeOff: '',
      }
      this.copyDrawerData = {}
      this.standardRowIndex = null
      this.standardInputAlert = {
        plankWidth: '',
        plankHeight: '',
        plankEdgeOff: '',
      }
    },
    handleEditSta(row, index) {
      this.showStandardDrawer = true
      this.drawerTitle = translate('basePlankManager.editStandardPlate')
      this.isCreateNewSlab = false
      this.drawerData = JSON.parse(JSON.stringify(row))
      this.copyDrawerData = JSON.parse(JSON.stringify(row))
      this.standardRowIndex = index
      this.standardInputAlert = {
        plankWidth: '',
        plankHeight: '',
        plankEdgeOff: '',
      }
    },
    handleCloseDrawer() {
      this.showStandardDrawer = false
      this.drawerData = {
        plankWidth: '',
        plankHeight: '',
        plankEdgeOff: '',
      }
      this.standardInputAlert = {
        plankWidth: '',
        plankHeight: '',
        plankEdgeOff: '',
      }
    },
    handleSaveDrawer(data, config) {
      let curData = {}
      Object.keys(data).forEach((key) => {
        curData[key] = Object.keys(this.standardInputAlert).includes(key)
          ? Number(data[key])
          : data[key]
      })
      if (config.rowIndex == null) {
        curData.standard_plank_id = genUniId()
        curData[webId] = curData.standard_plank_id
        this.standardData.push(curData)
      } else {
        this.$set(this.standardData, config.rowIndex, curData)
      }
      if (!config.isEdit) {
        this.save()
      }
      if (!config.isContinue) {
        this.showStandardDrawer = false
      } else {
        this.showStandardDrawer = true
      }
      this.isChangePicked = true
    },
    handleStandardSelect(selection) {
      this.selectStandardList = selection
    },
    // 删除标准板材
    handleDeleteStandard(row) {
      this.filterStandardList = []
      if (row) {
        this.filterStandardList = this.standardData.filter(
          (item) =>
            !(
              item.plankWidth == row.plankWidth &&
              item.plankHeight == row.plankHeight &&
              item.plankEdgeOff == row.plankEdgeOff
            )
        )
      } else {
        this.standardData.forEach((item) => {
          if (
            !this.selectStandardList.some(
              (selectItem) =>
                item.plankWidth === selectItem.plankWidth &&
                item.plankHeight === selectItem.plankHeight &&
                item.plankEdgeOff === selectItem.plankEdgeOff
            )
          ) {
            this.filterStandardList.push(item)
          }
        })
      }
      if (this.filterStandardList.length < 1) {
        this.$message.warning('请至少保留一块标准板材排板！')
        return
      }
      this.isDeleteStandard = true
    },
    // 确认删除标准板材
    confirmDeleteStandard() {
      if (
        this.filterStandardList.length === 1 ||
        this.filterStandardList.every((item) => !item.default_selected)
      ) {
        this.filterStandardList.forEach((item, index) => {
          Object.assign(item, { default_selected: index == 0 })
        })
      }
      this.standardData = this.filterStandardList
      this.save(false, '删除')
      this.isDeleteStandard = false
      this.isChangePicked = true
    },
    cancelDeleteStandard() {
      this.isDeleteStandard = false
    },
    handleChangeStandardSelect(row) {
      let list = JSON.parse(JSON.stringify(this.standardData))
      if (!row.default_selected) {
        list.forEach((item) => {
          if (item.standard_plank_id === row.standard_plank_id) {
            item.default_selected = true
          } else {
            item.default_selected = false
          }
        })
        this.standardData = list
        this.save()
        this.isChangePicked = true
      }
    },
    // 下料刀select聚焦事件
    blankFocus($index) {
      this.$nextTick(() => {
        //聚焦时改变下料刀row的索引 改变背景色
        if (this.blankTableRow != $index) {
          this.blankTableRow = $index
        }
        for (let _index = 0; _index < this.blankList.length; _index++) {
          if ($index == _index) {
            this.blankList[$index].isShowSaveBtn = false
            this.blankList[$index].isShowCancelBtn = true
          } else {
            this.blankList[_index].isShowSaveBtn = true
            this.blankList[_index].isShowCancelBtn = false
          }
        }
      })
    },
    // 下料刀单元格点击操作
    blankCellClick({ rowIndex }) {
      this.$nextTick(() => {
        if (this.blankTableRow != rowIndex) {
          this.blankTableRow = rowIndex
        }
      })
    },
    // 添加下料刀行索引
    blankCellClassName({ row, rowIndex }) {
      row.rowIndex = rowIndex
    },
    // 下料刀选中行样式更改
    blankSelectedRCellStyle({ rowIndex }) {
      if (this.blankTableRow === rowIndex) {
        return {
          'background-color': 'rgba(24, 168, 199, 0.2)',
        }
      }
    },
    // 获取下料刀表格数据
    async getBlankList() {
      const res = await getBlankTableList()
      this.blankList = res.data.data
      if (res.data.status === 1) {
        this.blankList.forEach((item) => {
          this.$set(item, 'isShowSaveBtn', true)
          this.$set(item, 'isShowCancelBtn', false)
        })
        this.isAddHandle = false
      } else {
        this.$message.error(res.data.msg)
      }
      // 保存成功,保存一份下料刀数据
      this.oldBlankList = JSON.parse(JSON.stringify(this.blankList))
      this.oldBlankList.forEach((item) => {
        this.handleBlankDataValue(item)
      })
    },
    // 下料刀取消操作
    handleCancelBlank($index) {
      // 保存过后的取消操作
      if (this.blankList[$index].id) {
        for (const key in this.oldBlankList[$index]) {
          if (Object.hasOwnProperty.call(this.oldBlankList[$index], key)) {
            // 将当前的下料刀数据重置为之前的数据
            this.blankList[$index][key] = this.oldBlankList[$index][key]
          }
        }
        this.blankList[$index].isShowSaveBtn = true
        this.blankList[$index].isShowCancelBtn = false
      } else {
        // 未保存的
        this.deleteBlankData($index)
        this.isAddHandle = false
      }
    },
    // 下料刀input校验
    handleBlankInput(val, row, prop, $index) {
      prop == 'thick' && verifyInputNumber(val, row, [prop], '2-1')
      this.handleBlankInputVerify(prop, $index, row)
    },
    // 下料刀blur空值校验
    handleBlankBlur(row, prop, $index) {
      this.handleBlankInputVerify(prop, $index, row)
    },
    // blankInput校验样式
    handleBlankInputVerify(prop, $index, row) {
      const inputRefs = `${prop}${$index}`
      let input = this.$refs[inputRefs].$refs.input
      let flag
      row[prop] && Number(row[prop]) != 0 ? (flag = true) : (flag = false)
      if (flag) {
        input.style.borderColor = ''
      } else {
        input.style.borderColor = 'red'
      }
    },
    // 编辑展示输入框
    showBlankInput($index, type) {
      for (let _index = 0; _index < this.blankList.length; _index++) {
        if ($index == _index) {
          this.blankList[$index].isShowSaveBtn = false
          this.blankList[$index].isShowCancelBtn = true
        } else {
          this.blankList[_index].isShowSaveBtn = true
          this.blankList[_index].isShowCancelBtn = false
        }
      }
      this.handleBlankInputfocus($index, true, type)
    },
    // 下料刀保存或者编辑
    confirmOrEditBlankItem($index) {
      this.blankList[$index].isShowSaveBtn = true
      this.blankList[$index].isShowCancelBtn = false
      // 1-> 未保存过走 create接口 post
      // 2-> 保存过走 update接口 put
      let data = JSON.parse(JSON.stringify(this.blankList[$index]))
      if (!this.blankList[$index].id) {
        this.handleBlankDataValue(data)
        this.createOrEditBlankRequeset(data, $index)
      } else {
        this.handleBlankDataValue(data, true)
        data.thick = Number(data.thick)
        this.createOrEditBlankRequeset(data, $index)
      }
    },
    // 删除下料刀不需要的参数
    handleBlankDataValue(data, del_ID = false) {
      delete data.isShowSaveBtn
      delete data.isShowCancelBtn
      delete data.rowIndex
      del_ID && delete data.id
    },
    // 新建保存Blank接口发送
    async createOrEditBlankRequeset(data, $index) {
      this.handleRepeat(data, $index)
      if (this.isRepeat) {
        // 保存失败
        this.$message.error(this.$t('basePlankManager.sameErr'))
        this.blankList[$index].isShowSaveBtn = false
        this.blankList[$index].isShowCancelBtn = true
        this.handleBlankInputfocus($index, true)
      } else {
        let res,
          id = this.blankList[$index].id
        id
          ? (res = await editBlankTableList(id, data))
          : (res = await createBlankTableList([data]))

        if (res.data.status === 1) {
          this.$message.success(res.data.msg)
          this.getBlankList()
        } else {
          this.$message.error(res.data.msg)
        }
      }
    },
    // 校验下料刀选择是否重复
    handleRepeat(data, $index) {
      let cloneBlankList
      if (this.blankList.length > 1) {
        cloneBlankList = JSON.parse(JSON.stringify(this.blankList)).filter(
          (_, index) => index != $index
        )
        for (let index = 0; index < cloneBlankList.length; index++) {
          if (
            cloneBlankList[index]['matCode'] == data['matCode'] &&
            cloneBlankList[index]['thick'] == data['thick']
          ) {
            this.isRepeat = true
            return
          } else {
            this.isRepeat = false
          }
        }
      }
    },
    // 删除下料刀具row right
    deleteBlankData($index) {
      const id = this.blankList[$index].id
      this.blankIndex = $index
      buryPointApi('base_material', 'delete')
      if (id) {
        this.isVisible = true
      } else {
        // 取消
        this.blankList.splice($index, 1)
        if ($index > 0) {
          this.blankTableRow = $index - 1
        }
      }
    },
    // 确认 删除下料刀具row right
    confirmDeleteBlankData() {
      const $index = this.blankIndex
      const id = this.blankList[$index].id
      this.deleteBlankRequest($index, id).finally(() => {
        this.isVisible = false
      })
    },
    // 取消 删除下料刀具row right
    cancelDeleteBlankData() {
      const $index = this.blankIndex
      const id = this.blankList[$index].id
      this.isVisible = false
      if (id) {
        this.$message({
          type: 'info',
          message: this.$t('basePlankManager.cancelDelete'),
        })
      }
    },
    // 删除下料刀接口发送 right
    async deleteBlankRequest($index, id) {
      const res = await deleteBlankTableList(id)
      if (res.data.status === 1) {
        this.$message.success(res.data.msg)
        this.getBlankList()
        if ($index > 0) {
          this.blankTableRow = $index - 1
        }
      } else {
        this.$message.error(res.data.msg)
      }
    },
    // 新建下料刀具 right
    createBlankData() {
      buryPointApi('base_material', 'add')
      const defaultBlankList = {
        matCode: '',
        thick: '',
        knife: 'default',
        isShowSaveBtn: false,
        isShowCancelBtn: true,
      }
      this.blankList.push(defaultBlankList)
      this.handleBlankInputfocus()
      let lastElementIndex = this.blankList.length - 1
      this.blankTableRow = lastElementIndex
      this.isAddHandle = true
    },
    // 新建下料刀具input聚焦
    handleBlankInputfocus($index, edit = false, type) {
      // 编辑聚焦当前row的材质
      if (edit) {
        this.$nextTick(() => {
          let refKey
          if (type) {
            refKey = `${type}${$index}`
          } else {
            refKey = `matCode${$index}`
          }
          const inputRefs = this.$refs[refKey]
          inputRefs.focus()
        })
      } else {
        // 添加或删除聚焦最后一条的材质
        this.$nextTick(() => {
          let len = this.blankList.length - 1
          let refKey = `matCode${len}`
          const inputRefs = this.$refs[refKey]
          inputRefs.focus()
        })
      }
    },
    // 初始化下料刀options数据 right
    initBlankSelectOptins() {
      for (let index = 0; index < 20; index++) {
        this.blankSelectOptins[index] = {
          label: 'T' + (index + 1),
          value: 'T' + (index + 1),
        }
      }
      this.blankSelectOptins.unshift({
        value: 'default',
        label: this.$t('basePlankManager.default'),
      })
    },
    showStandardEditInput(type, row, index) {
      if (row.plankWidth && row.plankHeight && row.plankEdgeOff) {
        const showStandardObj = row.isShowStandardEditInput
        const objKeys = Object.keys(showStandardObj)
        objKeys.forEach((item) => {
          item == type
            ? (showStandardObj[type] = true)
            : (showStandardObj[item] = false)
        })
        this.$nextTick(() => {
          this.$refs[`${type}${index}`].focus()
        })
      }
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex === 1) {
        return 'warning-row'
      } else if (rowIndex === 3) {
        return 'success-row'
      }
      return ''
    },
    getColor() {
      this.$token(
        '/load_all_color',
        {
          uid: this.userInfo.id,
          production_from: window.sessionStorage.getItem('thinkerx_material')
            ? 'guimen'
            : '',
        },
        (res) => {
          if (res.status == 1) {
            let list = res.data
            let newList = this.cacleColorItem(list)
            this.colorList = newList
          }
        }
      )
    },
    cacleColorItem(list) {
      let newList = []
      list.forEach((item) => {
        let currentItem = {
          children: [],
          value: item.name,
          label: item.name,
        }
        if (item.children.length) {
          currentItem.children = this.cacleColorItem(item.children)
        }
        if (item.color) {
          let itemColors = item.color.map((e) => {
            return {
              label: e.color_name,
              value: e.color_name,
            }
          })
          currentItem.children = currentItem.children.concat(itemColors)
        }
        newList.push(currentItem)
      })
      return newList
    },
    async getPlankMatCode() {
      // 对非必填进行处理
      let tempList = await fetchMaterialMatCodes()
      tempList.unshift({
        label: '',
        value: '空',
      })
      this.matcodeList = tempList
    },
    confirmSyncGuigui(from) {
      if (from) {
        let fid = sessionStorage.getItem('thinkerx_material_factory_id')
        fid = fid ? fid : this.userInfo.factory_id
        const url = sessionStorage.getItem('thinkerx_sync_testing_service')
          ? 'mensvsp-dev.thinkerx.com/api/panelSeo/getWhPanel?factory_id='
          : 'mensvsp.thinkerx.com/api/panelSeo/getWhPanel?factory_id='

        requestWithDualProtocol(url + fid)
          .then((res) => {
            if (res.data.status == 1) {
              this.slabList = res.data.data.flat(2)
              this.baseList = res.data.data.flat(2).map((item, index) => {
                return {
                  ...item,
                  id: index,
                  window_plank_id: item.plateId,
                  [webId]: genUniId(),
                }
              })
              this.recordId = this.baseList.length
                ? this.baseList.length - 1
                : 0
              this.handleSlabListData(this.baseList)
              // 因为会把原来列表的全部删除，所以把原来的列表全部放到删除列表里面
              this.deleteList = JSON.parse(JSON.stringify(this.oriList))
              this.save('sync', '同步', true)
              this.isChangePicked = false
            } else {
              this.$message({
                type: 'error',
                message: this.$t('basePlankManager.syncErr'),
              })
            }
          })
          .finally(() => {
            this.isSyncGuigui = false
          })
        return
      }
      this.$token('/sync_guigui_data', { uid: this.userInfo.id }, (res) => {
        if (res.status == 1) {
          this.isSyncGuigui = false
          this.$message({
            type: 'success',
            message: this.$t('basePlankManager.syncSuccess'),
          })
          this.getSlabList('sync')
        } else {
          this.$message({
            type: 'error',
            message: this.$t('basePlankManager.syncErr'),
          })
        }
      })
    },
    // 处理标准板材部分
    dealStandardRecord() {
      const standardChangeValue = []
      const standardDataObj = this.standardData[0]
      const oriStandardDataObj = this.oriStandardData[0]
      const standardList = [
        {
          type: 'plankWidth',
          text: this.$t('common.plankWidth'),
        },
        {
          type: 'plankHeight',
          text: this.$t('common.plankHeight'),
        },
        {
          type: 'plankEdgeOff',
          text: this.$t('basePlankManager.offsetSize'),
        },
      ]
      standardList.forEach((item) => {
        if (+standardDataObj[item.type] !== +oriStandardDataObj[item.type]) {
          standardChangeValue.push({
            key: item.text,
            value: standardDataObj[item.type] + '',
          })
        }
      })
      return standardChangeValue
    },
    // 处理特殊板材部分
    dealSpecialRecord() {
      // 添加的数组
      const addArr = this.baseList.filter((item) => {
        return item.operate_type === 'add'
      })
      // 剩下的数组包含编辑的以及没有编辑的
      const leaveArr = this.baseList.filter((item) => {
        return item.operate_type !== 'add'
      })
      // 真正删除的内容
      const realDeleteArr = this.deleteList.filter((item) => {
        return item.operate_type !== 'add'
      })
      // 将原始数组转换为对象
      let oriListToObj = {}
      this.oriList.forEach((item) => {
        oriListToObj[item.id] = item
      })
      let EditArr = []
      leaveArr.forEach((item) => {
        // 比较这些属性
        let arr = [
          'matCode',
          'isPicked',
          'color',
          'thick',
          'width',
          'height',
          'trim_edge',
        ]
        arr.some((prop) => {
          if (oriListToObj[item.id][prop] !== item[prop]) {
            item.operate_type = 'edit'
            EditArr.push(item)
            // 表明发生编辑
            return true
          }
          return false
        })
      })
      let specialChangeValue = []
      if (addArr.length) {
        specialChangeValue.push({
          key:
            this.saveType === 'sync'
              ? this.$t('basePlankManager.syncDataTip', {
                  fromMc: this.fromMc,
                })
              : this.$t('common.add'),
          type: operateRecordTableType.SPECIAL_MATERIAL_TABLE,
          arr: addArr,
        })
      }
      if (EditArr.length) {
        specialChangeValue.push({
          key: this.$t('common.edit'),
          type: operateRecordTableType.SPECIAL_MATERIAL_TABLE,
          arr: leaveArr,
        })
      }
      if (realDeleteArr.length) {
        specialChangeValue.push({
          key: this.$t('common.delete'),
          type: operateRecordTableType.SPECIAL_MATERIAL_TABLE,
          arr: realDeleteArr,
        })
      }
      return specialChangeValue
    },
    // 保存操作记录
    saveOperateRecord() {
      let record = []
      // ----------先处理标准板材--------------
      const standardChangeValue = this.dealStandardRecord()
      const specialChangeValue = this.dealSpecialRecord()
      if (standardChangeValue.length) {
        record.push({
          key: '标准板材',
          value: standardChangeValue,
        })
      }
      if (specialChangeValue.length) {
        record.push({
          key: '特殊板材',
          value: specialChangeValue,
        })
      }
      // ----------处理特殊板材--------------
      if (standardChangeValue.length || specialChangeValue.length) {
        saveDataJsonToOss(record, (url) => {
          this.$token(
            '/save_operation_record',
            {
              uid: this.userInfo.id,
              action_type: 'origin_plank_management',
              action_url: url,
            },
            () => {
              this.deleteList = [] // 重置删除列表
            }
          )
        })
      }
    },
    genCompareFormData(current, ori) {
      let compareMsg = [
        {
          title: '标准板材',
          keys: ['plankWidth', 'plankHeight', 'plankEdgeOff'],
        },
      ]
      return {
        type: 'form',
        current,
        ori,
        compareMsg,
        formTextObj: baseMaterialFormTextObj,
      }
    },
    genCompareTableData(current, ori) {
      return current.standardList
        ? [
            {
              type: 'table',
              list: current.list,
              oriList: ori.list,
              keys: [
                'matCode',
                'isPicked',
                'color',
                'thick',
                'width',
                'height',
                'trim_edge',
              ],
              tableType: operateRecordTableType.SPECIAL_MATERIAL_TABLE,
              key:
                this.saveType === 'sync' ? '特殊板材-同步柜柜数据' : '特殊板材', // 表格对应的key
            },
            {
              type: 'table',
              list: current.standardList,
              oriList: ori.standardList,
              keys: [
                'plankWidth',
                'plankHeight',
                'plankEdgeOff',
                'default_selected',
              ],
              tableType: operateRecordTableType.STANDARD_MATERIAL_TABLE,
              key: '标准板材', // 表格对应的key
            },
          ]
        : [
            {
              type: 'table',
              list: current.list,
              oriList: ori.list,
              keys: [
                'matCode',
                'isPicked',
                'color',
                'thick',
                'width',
                'height',
                'trim_edge',
              ],
              tableType: operateRecordTableType.SPECIAL_MATERIAL_TABLE,
              key:
                this.saveType === 'sync' ? '特殊板材-同步柜柜数据' : '特殊板材', // 表格对应的key
            },
          ]
    },
    // 生成最终的操作记录
    genFinalRecordFn(current, ori) {
      let recordData = {
        key: '',
        operateArr: [
          // 应对多个操作页面时，比如雕刻机的操作页面
          {
            key: '',
            dataArr: this.genCompareTableData(current, ori), // 如果时同步操作就仅仅比较table
          },
        ],
      }
      // this.getSlabList()
      genFinalRecord(recordData, 'origin_plank_management', () => {
        // if (!this.isChangePicked) {
        //   this.getSlabList()
        // }
      })
    },
    async save(type, action_type, isSpecial = false, formData = null) {
      this.saveType = type
      let defaultPlankInfo =
        this.standardData.length === 1
          ? {
              plankEdgeOff: Number(this.standardData[0].plankEdgeOff),
              plankWidth: Number(this.standardData[0].plankWidth),
              plankHeight: Number(this.standardData[0].plankHeight),
              default_selected: this.standardData[0].default_selected,
              standard_plank_id: this.standardData[0].standard_plank_id,
            }
          : this.standardData.map((item) => ({
              plankEdgeOff: Number(item.plankEdgeOff),
              plankWidth: Number(item.plankWidth),
              plankHeight: Number(item.plankHeight),
              default_selected: item.default_selected,
              standard_plank_id: item.standard_plank_id,
            }))

      let payLoad = {
        uid: this.userInfo.id,
        data: {},
      }
      if (isSpecial) {
        payLoad.data.specialPlankInfo = this.baseList
        if (action_type == '删除') {
          payLoad.data.special_plank_del_ids = this.deleteList[0].children
            ? this.deleteList[0].children.map((item) => item.id)
            : this.deleteList.map((item) => item.id)
        }
      } else {
        payLoad.data.defaultPlankInfo = defaultPlankInfo
      }
      if (action_type == '修改' && !isSpecial) {
        Reflect.deleteProperty(payLoad.data, 'defaultPlankInfo')
        payLoad.data = {
          ...payLoad.data,
          ...formData,
        }
      }
      if (window.sessionStorage.getItem('thinkerx_material')) {
        payLoad.production_from = 'guimen'
      }
      this.disableSaveBtn = true
      try {
        this.isLoading = true
        const res = await updateBasePlank(payLoad)
        this.disableSaveBtn = false
        if (res.data.status == 1) {
          try {
            this.genFinalRecordFn(
              {
                standardList: this.standardData,
                list: this.baseList,
              },
              {
                standardList: this.oriStandardData,
                list: this.oriList,
              }
            )
            await this.getSlabList()
            this.deleteList = []
          } catch (error) {
            console.error('error', error)
          }
          this.$message({
            type: 'success',
            message: action_type
              ? this.$t('common.formatSuccess', { type: action_type })
              : this.$t('common.saveSuccess'),
          })
          if (action_type == '修改') {
            if (this.copyNewSlab && Object.keys(this.copyNewSlab).length) {
              this.copyNewSlab = this.baseList.find(
                (item) => item.id == this.copyNewSlab.id
              )
            }
          }
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg,
          })
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: '网络异常请稍后重试',
        })
      }
    },
    async handleSettingSuccess() {
      await this.getSlabList()
    },
    handleSelectKnife() {
      buryPointFunc('原片管理-原片下料刀选择')
    },
    // 勾选默认选用
    changePicked(plank) {
      this.isChangePicked = true
      let list = JSON.parse(JSON.stringify(this.baseList))
      if (plank.isPicked) {
        list.forEach((item) => {
          if (
            item.matCode == plank.matCode &&
            item.thick == plank.thick &&
            item.color == plank.color &&
            item.id != plank.id
          ) {
            item.isPicked = false
          } else if (item.id == plank.id) {
            item.isPicked = true
          }
        })
      } else {
        list.forEach((item) => {
          if (item.id == plank.id) {
            item.isPicked = false
          }
        })
      }
      this.baseList = list
      this.save(false, '修改', true)
      this.handleSlabListData(list)
    },
    handleSelectionChange(val) {
      this.selectionList = val
      this.selecteds = []
      this.deleteBaseItems = []
      val.forEach((item) => {
        if (item.children) {
          item.children.forEach((e) => {
            if (this.selecteds.indexOf(e.id) == -1 && e.id > -1) {
              this.selecteds.push(e.id)
              this.deleteBaseItems.push(e)
            }
          })
        } else {
          if (this.selecteds.indexOf(item.id) == -1) {
            this.selecteds.push(item.id)
            this.deleteBaseItems.push(item)
          }
        }
      })
    },
    //默认板材输入限制
    defaultInputCheck(value, index) {
      this.standardData[index][value] = regReplaceBaseMaterialDefaultInputCheck(
        this.standardData[index][value]
      )
      this.standardData[index][value] =
        this.standardData[index][value].indexOf('.') > 0
          ? this.standardData[index][value].split('.')[0].substring(0, 5) +
            '.' +
            this.standardData[index][value].split('.')[1]
          : this.standardData[index][value].substring(0, 5) //整数部分保留五位数
    },
    // 全选所有特殊板材
    // selectAllSlab() {
    //   let status = true
    //   if (!this.isSelectAll) {
    //     status = false
    //   }
    //   for (let i = 0; i < this.slabList.length; ++i) {
    //     this.slabList[i].isSelected = status
    //   }
    //   this.$forceUpdate()
    // },
    // 选中单个特殊板材
    // selectedSlab(item) {
    //   let flag = true
    //   for (let i = 0; i < this.slabList.length; ++i) {
    //     if (!this.slabList[i].isSelected) {
    //       flag = false
    //       break
    //     }
    //   }
    //   if (flag) {
    //     this.isSelectAll = true
    //   } else {
    //     this.isSelectAll = false
    //   }
    // },
    //默认勾选该板材进行排版
    // selectPaiban() {},
    // 新增板件抽屉
    addNewSlab() {
      buryPointApi('base_material', 'add_special')
      this.showNewDrawer = true
      this.newSlab = {
        matCode: this.baseMaterialMatCode,
        color:
          this.baseMaterialColorList[this.baseMaterialColorList.length - 1] ??
          '',
        thick: '',
        width: '',
        height: '',
        trim_edge: '',
        is_high_gloss_plank: 0,
      }
      this.newPlankInfoTexture = this.baseMaterialColorList
    },
    //打开编辑特殊板材抽屉
    showEditSlab(item) {
      if (item.children || item.plateId) {
        return
      }
      this.inputAlert = {
        slabLengthInputAlert: '',
        slabWidthInputAlert: '',
        thickInputAlert: '',
        typeInputAlert: '',
        colorInputAlert: '',
        trimEdgeInputAlert: '',
      }
      this.newSlab = JSON.parse(JSON.stringify(item))
      this.copyNewSlab = JSON.parse(JSON.stringify(item))
      this.inputColorValue = item.color
      this.showEditDrawer = true
      this.currentSlab = item
      this.colorSearchKey = item.color
      this.handleEnterColor()
    },
    // 显示删除余料弹窗
    showDeleteDialog(val = null) {
      if (val) {
        this.deleteBaseItems = [val]
      }
      this.isShowDeleteDialog = true
    },
    // 确认删除余料
    confirmDeleteSlab() {
      let deVal = this.deleteBaseItems
      let list = this.baseList
      // 添加删除的item
      this.deleteList.push(...deVal)
      deVal.forEach((evt) => {
        if (evt.children) {
          let childs = evt.children
          childs.forEach((item) => {
            list.splice(
              list.findIndex((e) => e.id == item.id),
              1
            )
          })
        } else {
          list.splice(
            list.findIndex((e) => e.id == evt.id),
            1
          )
        }
      })
      this.deleteBaseItems = []
      this.baseList = list
      this.handleSlabListData(list)
      this.isShowDeleteDialog = false
      this.save(false, '删除', true)
      this.isChangePicked = false
    },
    // 聚合相同板材材质、板材颜色、板材厚度的内容
    handleSlabListData(arr) {
      let newArr = []
      if (arr.length) {
        let id = -1
        newArr = [{ ...arr[0] }]
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < newArr.length; j++) {
            if (
              arr[i].matCode == newArr[j].matCode &&
              arr[i].color == newArr[j].color &&
              arr[i].thick == newArr[j].thick
            ) {
              if (newArr[j].hasOwnProperty('children')) {
                newArr[j].children.push({ ...arr[i] })
              } else {
                newArr[j].children = []
                newArr[j].children.push({ ...arr[i] })
              }
              break
            } else {
              if (
                newArr.filter(
                  (item) =>
                    arr[i].matCode == item.matCode &&
                    arr[i].color == item.color &&
                    arr[i].thick == item.thick
                ).length
              ) {
                continue
              } else {
                newArr.push({ ...arr[i], children: [] })
              }
            }
          }
        }
        newArr.forEach((item) => {
          if (item.children && item.children.length < 2) {
            delete item.children
          } else {
            item.rowId = id
            --id
          }
        })
      }
      this.slabList = newArr
    },
    // 获取余料仓数据
    async getSlabList(type) {
      try {
        this.recordId = 5
        this.handleSlabListData(this.baseList)
        let params = {
          uid: this.userInfo.id,
        }
        if (window.sessionStorage.getItem('thinkerx_material')) {
          params.production_from = 'guimen'
        }
        this.isLoading = true
        const res = await getBasePlank(params)
        if (res.data.status == 1) {
          let data = res.data.data
          const {
            isAutoSelected,
            auto_add_special_plate,
            only_layout_too_big,
          } = data
          this.settingData = {
            isAutoSelected,
            autoAddSpecialPlate: auto_add_special_plate,
            onlyLayoutTooBig: only_layout_too_big,
          }
          if (Array.isArray(data.defaultPlankInfo)) {
            this.standardData = data.defaultPlankInfo.map((item) => ({
              ...item,
              [webId]: item.standard_plank_id,
            }))
          } else {
            this.standardData = [
              {
                ...data.defaultPlankInfo,
                [webId]: data.defaultPlankInfo.standard_plank_id,
              },
            ]
          }
          this.oriStandardData = JSON.parse(JSON.stringify(this.standardData))
          this.blankList = data.plate_knife_map
          this.blankList.forEach((item) => {
            this.$set(item, 'isShowSaveBtn', true)
            this.$set(item, 'isShowCancelBtn', false)
          })
          // 保存一份下料刀数据
          this.oldBlankList = JSON.parse(JSON.stringify(this.blankList))
          this.oldBlankList.forEach((item) => {
            this.handleBlankDataValue(item)
          })
          this.baseList = data.specialPlankInfo.map((item, index) => {
            return {
              ...item,
              // 统一设置判断高光板属性
              is_high_gloss_plank: item.matCode.startsWith(
                this.$t('common.heighGloss') + '_'
              )
                ? 1
                : 0,
              [webId]: genUniId(),
              rowId: index,
            }
          })
          if (type === 'sync') {
            // 此时说明是同步造成的更新
            this.saveType = 'sync'
            try {
              this.genFinalRecordFn(
                {
                  list: this.baseList,
                },
                {
                  list: this.oriList,
                }
              )
            } catch (error) {
              console.error('error', error)
            }
            // 保存操作记录
          } else {
            this.saveType = ''
          }
          // 保存原始数据
          this.oriList = JSON.parse(JSON.stringify(this.baseList))
          this.recordId = this.baseList.length ? this.baseList.length - 1 : 0
          this.handleSlabListData(this.baseList)
          this.oriBlankList = JSON.parse(JSON.stringify(this.blankList))
        }
      } catch (error) {
        console.error(error)
      } finally {
        // 防止过快，页面会闪烁
        setTimeout(() => {
          this.isLoading = false
        }, 100)
      }
    },
    //关闭特殊板材编辑抽屉
    closeEditDrawer() {
      this.showEditDrawer = false
      this.newPlankInfoTexture = ''
      this.colorSearchKey = ''
      this.inputAlert = {
        thickInputAlert: '',
        typeInputAlert: '',
        colorInputAlert: '',
        slabLengthInputAlert: '',
        slabWidthInputAlert: '',
        trimEdgeInputAlert: '',
      }
      this.inputColorValue = ''
    },
    //确认编辑特殊板材
    confirmEditSlab() {
      // 判断是否可以保存
      let ableSave = this.checkInputIsBlank()
      if (!ableSave) {
        this.$message.error(this.$t('basePlankManager.editErrTip'))
        return
      }
      // if (this.inputColorValue) {
      //   this.newSlab.color = this.inputColorValue
      // }
      this.newSlab.color =
        JSON.stringify(this.newPlankInfoTexture) == '[]'
          ? ''
          : this.newPlankInfoTexture
          ? this.newPlankInfoTexture[this.newPlankInfoTexture.length - 1]
          : this.newSlab.color
      // 判断是否编辑
      const isEdit =
        this.copyNewSlab &&
        this.copyNewSlab.matCode == this.newSlab.matCode &&
        this.copyNewSlab.color == this.newSlab.color &&
        this.copyNewSlab.thick == this.newSlab.thick &&
        this.copyNewSlab.width == this.newSlab.width &&
        this.copyNewSlab.height == this.newSlab.height &&
        this.copyNewSlab.trim_edge == this.newSlab.trim_edge &&
        this.copyNewSlab.is_high_gloss_plank == this.newSlab.is_high_gloss_plank
      if (isEdit) {
        this.showEditDrawer = false
        return
      }
      let data = JSON.parse(JSON.stringify(this.newSlab))
      if (this.cancelIsRepeat(data)) {
        this.$message.error(this.$t('common.sameErr'))
        return
      }
      // 根据高光板选项确定材质
      const matCode = this.handlePlankMatCode(data, data.is_high_gloss_plank)
      data = { ...data, matCode }

      data.thick = data.thick == '' ? data.thick : Number(data.thick)
      this.baseList[this.baseList.findIndex((item) => item.id == data.id)] =
        data
      this.handleSlabListData(this.baseList)
      this.showEditDrawer = false
      this.save(false, '修改', true)
      this.isChangePicked = false
    },
    //关闭添加特殊板材抽屉
    closeNewDrawer() {
      this.showNewDrawer = false
      this.inputAlert = {
        thickInputAlert: '',
        typeInputAlert: '',
        colorInputAlert: '',
        slabLengthInputAlert: '',
        slabWidthInputAlert: '',
        trimEdgeInputAlert: '',
      }
      this.inputColorValue = ''
    },
    cancelIsRepeat(plank) {
      let repeat = false
      let list = this.baseList
      const matCode = this.handlePlankMatCode(plank, plank.is_high_gloss_plank)
      for (let i = 0; i < list.length; i++) {
        let item = list[i]
        if (
          item.matCode == matCode &&
          item.color == plank.color &&
          item.thick == plank.thick &&
          item.width == plank.width &&
          item.height == plank.height &&
          item.trim_edge == plank.trim_edge
        ) {
          repeat = true
          break
        }
      }
      return repeat
    },
    //清空信息
    clearNewPlankInfo() {
      this.newPlankInfoTexture = ''
      this.colorSearchKey = ''
      for (let key in this.newSlab) {
        this.newSlab[key] = ''
      }
      this.inputAlert = {
        thickInputAlert: '',
        typeInputAlert: '',
        colorInputAlert: '',
        slabLengthInputAlert: '',
        slabWidthInputAlert: '',
        trimEdgeInputAlert: '',
      }
      this.inputColorValue = ''
    },
    // 输入框输入事件
    inputCheck(type) {
      switch (type) {
        // trimEdgeInputAlert
        case 'trim_edge':
          if (this.dealDecimal(type)) {
            this.inputAlert.trimEdgeInputAlert = this.$t(
              'materialPage.edgeNumberLimit'
            )
          } else if (this.newSlab.trim_edge == '') {
            this.inputAlert.trimEdgeInputAlert = this.$t(
              'materialPage.edgeEmptyTip'
            )
          } else {
            this.inputAlert.trimEdgeInputAlert = ''
          }
          break
        case 'height':
          if (this.dealDecimal(type)) {
            this.inputAlert.slabLengthInputAlert = this.$t(
              'basePlankManager.numberCheck',
              { type: this.$t('common.plankHeight') }
            )
          } else if (
            this.newSlab.height == '' ||
            Number(this.newSlab.height) == 0
          ) {
            this.inputAlert.slabLengthInputAlert = this.$t(
              'basePlankManager.heightErr'
            )
          } else {
            this.inputAlert.slabLengthInputAlert = ''
          }
          break
        case 'width':
          if (this.dealDecimal(type)) {
            this.inputAlert.slabWidthInputAlert = this.$t(
              'basePlankManager.numberCheck',
              { type: this.$t('common.plankWidth') }
            )
          } else if (
            this.newSlab.width == '' ||
            Number(this.newSlab.width) == 0
          ) {
            this.inputAlert.slabWidthInputAlert = this.$t(
              'basePlankManager.widthErr'
            )
          } else {
            this.inputAlert.slabWidthInputAlert = ''
          }
          break
        case 'thick':
          if (this.dealDecimal(type)) {
            this.inputAlert.thickInputAlert = this.$t(
              'basePlankManager.numberCheck',
              { type: this.$t('common.plankThick') }
            )
          } else {
            this.inputAlert.thickInputAlert = ''
          }
          break
        case 'matCode':
          this.newSlab.matCode =
            this.newSlab.matCode !== '空' ? this.newSlab.matCode : ''
          if (this.isThinkerxMaterial) {
            this.newSlab.matCode = this.newSlab.matCode.replace(
              /^\s+|\s+$/g,
              ''
            )
          }
          if (this.newSlab.matCode == '') {
            this.inputAlert.typeInputAlert = this.$t(
              'basePlankManager.matCodeErr'
            )
          } else {
            this.inputAlert.typeInputAlert = ''
          }
          break
        case 'color':
          if (this.isThinkerxMaterial) {
            this.newSlab.color = this.newSlab.color.replace(/^\s+|\s+$/g, '')
            if (this.newSlab.color == '') {
              this.inputAlert.colorInputAlert = this.$t(
                'basePlankManager.colorErr'
              )
            } else {
              this.inputAlert.colorInputAlert = ''
            }
          } else {
            if (this.inputColorValue == '') {
              this.inputAlert.colorInputAlert = this.$t(
                'basePlankManager.colorErr'
              )
            } else {
              this.inputAlert.colorInputAlert = ''
            }
          }
          break
      }
      if (type == 'matCode') return
      this.newSlab[type] =
        this.newSlab[type].indexOf('.') > 0
          ? this.newSlab[type].split('.')[0].substring(0, 5) +
            '.' +
            this.newSlab[type].split('.')[1]
          : this.newSlab[type].substring(0, 5) //整数部分保留五位数
    },
    dealDecimal(type) {
      let reg = /[^\d.]/
      let flag = false
      if (this.newSlab[type] !== '空' && reg.test(this.newSlab[type])) {
        flag = true
      }
      this.newSlab[type] = regReplaceBaseMaterialDealDecimal(
        String(this.newSlab[type]),
        true
      )
      return flag
    },
    // 判断输入是否为空
    checkInputIsBlank() {
      let ableSave = true
      if (this.newSlab.height == '' || Number(this.newSlab.height) == 0) {
        this.inputAlert.slabLengthInputAlert = this.$t(
          'basePlankManager.heightErr'
        )
        ableSave = false
      }
      if (this.newSlab.width == '' || Number(this.newSlab.width) == 0) {
        this.inputAlert.slabWidthInputAlert = this.$t(
          'basePlankManager.widthErr'
        )
        ableSave = false
      }
      if (this.newSlab.trim_edge === '') {
        this.inputAlert.trimEdgeInputAlert = this.$t(
          'materialPage.edgeEmptyTip'
        )
        ableSave = false
      }
      return ableSave
    },
    // 添加板件后清空输入框继续添加下一块板件
    addNewRowContinue() {
      this.saveNewSlab(true)
    },
    // 保存新增余料
    saveNewSlab(noClose = false) {
      // 判断是否可以保存
      let ableSave = this.checkInputIsBlank()
      if (!ableSave) return
      let newSlab = JSON.parse(JSON.stringify(this.newSlab))
      newSlab.color =
        this.newPlankInfoTexture[this.newPlankInfoTexture.length - 1] ?? ''
      newSlab.height = Number(newSlab.height)
      newSlab.thick =
        newSlab.thick == '' ? newSlab.thick : Number(newSlab.thick)
      newSlab.width = Number(newSlab.width)
      // newSlab.color = this.newSlabColor
      // if (!this.isThinkerxMaterial) {
      //   newSlab.color = this.inputColorValue
      // }
      if (this.cancelIsRepeat(newSlab)) {
        this.$message.error(this.$t('basePlankManager.sameErr'))
        return
      }
      if (
        this.baseList.filter((item) => {
          return (
            item.matCode == newSlab.matCode &&
            item.color == newSlab.color &&
            item.thick == newSlab.thick &&
            item.isPicked
          )
        }).length
      ) {
        newSlab.isPicked = false
      } else {
        newSlab.isPicked = true
      }
      newSlab.rowId = ++this.recordId
      newSlab[webId] = genUniId()
      // 根据高光板选项确定材质
      const matCode = this.handlePlankMatCode(
        newSlab,
        newSlab.is_high_gloss_plank
      )
      this.baseList.push({ ...newSlab, matCode })
      this.handleSlabListData(this.baseList)
      this.save(false, this.$t('common.save'), true)
      if (!noClose) {
        this.showNewDrawer = false
      }
      this.isChangePicked = false
    },
    // 颜色输入框聚焦
    handleColorInpFocus() {
      // 隐藏色卡input
      this.showCascaderOrInp = ''
      const cascader = this.$refs.colorCascaderRef
      this.inputEl = cascader.$refs.input.$el.querySelector('input')
      this.$nextTick(() => {
        // 色卡级联聚焦
        this.inputEl.focus()
        // 触发点击option出现
        cascader.$el.click()
        this.inputColorValue ? (this.inputEl.value = this.inputColorValue) : ''
      })
    },
    // 颜色级联 失焦 || 选择
    cascaderBlurOrChange(e, type) {
      if (type === 'blur' && !this.newSlab.color) {
        // 关闭弹窗
        return
      }
      if (this.isColorEnterEvent) return
      type === 'change'
        ? (this.inputColorValue = e[e.length - 1])
        : type === 'blur'
        ? (this.inputColorValue = e.target.value)
        : null
      this.inputCheck('color')
      // 展示input
      this.showCascaderOrInp = 'input'
    },
    // 色卡级联input回车事件绑定
    colorEnterHandle(visibleEv) {
      if (visibleEv) {
        this.inputEl.addEventListener('keyup', (e) => {
          if (e.code === 'Enter') {
            this.isColorEnterEvent = true
            this.inputColorValue = this.inputEl.value
            // 展示input
            this.showCascaderOrInp = 'input'
          }
        })
      }
      this.isColorEnterEvent = false
      this.newSlab.color = ''
    },
    handlePlankMatCode(part, type) {
      let { matCode } = part
      // 确定新的材质
      if (matCode.startsWith(this.$t('common.heighGloss') + '_') && !type) {
        matCode = matCode.replace(this.$t('common.heighGloss') + '_', '')
      } else if (
        !matCode.startsWith(this.$t('common.heighGloss') + '_') &&
        type
      ) {
        matCode = this.$t('common.heighGloss') + '_' + matCode
      }
      return matCode
    },
    changePlankType(value, row) {
      const matCode = this.handlePlankMatCode(row, value)
      // 更新材质
      const newSlab = { ...row, matCode }
      this.newSlab = newSlab
      this.inputColorValue = newSlab.color
      this.confirmEditSlab()
    },
    handleSelectTexture(val) {
      this.newSlabColor = val[val.length - 1]
      this.colorSearchKey = ''
      this.setBaseMaterialColorList(val)
    },
    handleSelectMatCode(val) {
      this.newSlab.matCode = val == '空' ? '' : val
      this.setBaseMaterialMatCode(val == '空' ? '' : val)
    },
    //按回车对材质进行的操作
    handleEnterMatCode(e) {
      // 编辑时使用this.newSlab.matCode的值去判断
      const inputMatCode = e?.target?.value ?? this.newSlab.matCode
      // 当为空或者全是空格时不进行操作
      if (!inputMatCode) return
      // 判断数组是否已经存在当前添加的数据
      const isExist = checkMatCodeIsExist(inputMatCode, this.matcodeList)
      // 获取本地的材质数据
      let otherMatCodes = localStorage.getItem('matcodeList')
        ? JSON.parse(localStorage.getItem('matcodeList'))
        : []
      if (!isExist) {
        // 如果不存在
        otherMatCodes.push(inputMatCode)
        localStorage.setItem('matcodeList', JSON.stringify(otherMatCodes))
        // 将数据添加到数组中，并且显示
        let matcodeSelectOption = {
          label: inputMatCode,
          value: inputMatCode,
        }
        this.matcodeList.push(matcodeSelectOption)
      }
      // 保证条目被选中
      this.newSlab.matCode = inputMatCode
      this.setBaseMaterialMatCode(inputMatCode)
    },
    handleEnterColor(e) {
      if (!this.colorSearchKey) return
      const isExist = checkColorIsExist(
        this.colorSearchKey,
        this.materialColorOptions
      )
      let otherColors = localStorage.getItem('colorList')
        ? JSON.parse(localStorage.getItem('colorList'))
        : []
      if (isExist) {
        this.newPlankInfoTexture = isExist
      } else {
        otherColors.push(this.colorSearchKey)
        this.newPlankInfoTexture = ['other', this.colorSearchKey]
        localStorage.setItem('colorList', JSON.stringify(otherColors))
        const lastIndex = this.materialColorOptions.length - 1
        this.materialColorOptions[lastIndex].children.push({
          label: this.colorSearchKey,
          value: this.colorSearchKey,
        })
      }
      if (e?.target?.value) {
        this.setBaseMaterialColorList(this.newPlankInfoTexture)
      }
    },
    onBeforeFilter(val) {
      this.colorSearchKey = val
    },
    handleDeleteColorSearch(type) {
      const ref =
        type == 'edit'
          ? this.$refs['editColorCascaderRef']
          : this.$refs['addColorCascaderRef']
      this.colorSearchKey = ref.$children[0].value
    },
    // 打开设置模态框
    handleOpenSettingModal() {
      this.isShowSettingModal = true
    },
    translateLang(key, values) {
      return translate(key, values)
    },
  },
  watch: {
    'newSlab.is_high_gloss_plank': {
      handler(newVal) {
        if (newVal) {
          buryPointApi('base_material', 'high_light')
        }
      },
    },
    materialColorList: {
      handler(val) {
        this.materialColorOptions = val
      },
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .custom-style,
.el-input {
  width: 206px !important;
}

/deep/ .custom-button,
.el-button--primary.is-disabled {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

/deep/ .custom-button {
  color: #fff;
  background: #18a8c7;
  border-color: #18a8c7;
}

.base-material {
  width: 100%;
  // min-width: 1440px;
  // background: #eee;

  .small-title {
    color: #333;
    font-weight: 500;
    // height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, 'PingFang SC';
    line-height: 52px;
  }

  .base-content {
    margin: 16px;

    .standard-slab {
      .standard_slab_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 24px;
        background: #fff;
      }

      .standard-slab-title {
        display: flex;
        .first-level {
          display: flex;
          align-items: center;
        }

        .bluelable {
          width: 4px;
          height: 14px;
          margin: 19px 16px;
          background: #18a8c7;
          border-radius: 1px;
        }

        .select-larger {
          padding-left: 16px;
          color: #666;
        }
      }

      .standard-table {
        .standard-table-header {
          /deep/.el-table__header-wrapper {
            height: 40px;

            .el-table__header {
              width: 100%;
              height: 40px;
            }

            .has-gutter tr {
              height: 40px !important;
              line-height: 40px;

              .is-leaf {
                padding: 0;
                background: #f5f5f5;
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                box-shadow: 0 -1px 0 0 rgb(204, 204, 204),
                  0 1px 0 0 rgb(204, 204, 204);

                // height: 40px;
                .cell {
                  display: inline-block;
                  height: 40px;
                  color: #333;
                  font-weight: 500;
                  font-size: 14px;
                  font-family: PingFangSC-Medium, 'PingFang SC';
                  line-height: 40px;
                }
              }
            }
          }

          .el-table__body-wrapper {
            overflow: scroll;

            &::-webkit-scrollbar {
              display: none;
            }

            /deep/.el-table__row tr {
              height: 40px;
            }
          }

          // /deep/.el-input__inner {
          //   border: 1px solid #fff;
          // }
        }
      }
    }

    .add-btn {
      color: #fff;
      background: #18a8c7;
    }

    .add-btn-disable {
      color: #fff;
      background-color: #ccc;
      border-color: #ccc;
    }

    .delete-btn {
      color: #333;
      background: #fafafa;
      border: 1px solid #ddd;

      .delete-btn-hover {
        color: #333;

        &:hover {
          color: #18a8c7;
        }
      }
    }

    /deep/ .defaultCheck {
      .el-checkbox__inner {
        border-radius: 50%;
      }

      .is-checked {
        .el-checkbox__inner {
          background-color: rgb(24, 168, 199);
          border-color: rgb(24, 168, 199);
        }
      }
    }

    .special-slab {
      .first-level {
        display: flex;
        align-items: center;
        height: 100%;
      }
      .standard-slab-title {
        display: flex;
        .auto-add-special {
          .first-level {
            height: auto;
          }
          .second-level {
            margin-left: 10px;
          }
        }
        .bluelable {
          width: 4px;
          height: 14px;
          margin: 19px 16px;
          background: #18a8c7;
          border-radius: 1px;
        }

        .select-larger {
          padding-left: 16px;
          color: #666;
        }
        /deep/ .auto-add-new .new-button_text {
          top: -10px;
        }
      }

      .special-slab-list {
        /deep/ .el-table {
          .is_high_gloss_plank {
            .cell {
              overflow: visible;
            }
          }
        }
      }

      .top-operation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;

        .ant-checkbox-wrapper {
          height: 20px;
          padding-left: 16px;
          color: #666;
          font-weight: 400;
          font-size: 14px;
          font-family: PingFangSC-Regular, 'PingFang SC';
          line-height: 20px;
        }

        .top-operation-right {
          display: flex;
          align-items: center;
          margin-right: 16px;
        }
      }

      .special-slab-btn {
        width: 138px;
        height: 32px;
        margin-left: 8px;
        font-weight: 400;
        font-size: 14px;
        font-family: PingFangSC-Regular, 'PingFang SC';
      }

      .synchronize-btn,
      .setting-btn {
        background: #fafafa;
      }

      .slab-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        background: #f5f5f5;
        box-shadow: 0 -1px 0 0 rgb(204, 204, 204), 0 1px 0 0 rgb(204, 204, 204);

        span {
          display: inline-block;
          color: #333;
          font-weight: 500;
          font-size: 14px;
          font-family: PingFangSC-Medium, 'PingFang SC';
          line-height: 40px;
        }
      }

      .slab-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        line-height: 40px;
        box-shadow: 0 1px 0 0 rgb(204, 204, 204);
      }

      .slab-row-content {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .slab-cell {
        padding-left: 16px;
        color: #333;
        font-size: 14px;
        cursor: default;
      }

      .slab-row-operation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 16px;
        color: #18a8c7;
        font-size: 14px;

        span {
          cursor: pointer;
        }

        .default-choice {
          /deep/.ant-checkbox-inner {
            border-radius: 50%;

            .ant-checkoutbox-checked::after {
              border: none;
            }
          }
        }
      }

      .plank-list-table-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        padding: 0 24px;
        color: #333;
        font-size: 14px;
        line-height: 40px;
        background: #edf9fc;
      }
    }

    // 原片下料刀选择
    .cutting-select {
      .standard_slab_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 24px;
        background: #fff;

        .standard-slab-title {
          display: flex;

          .bluelable {
            width: 4px;
            height: 14px;
            margin: 19px 16px;
            background: #18a8c7;
            border-radius: 1px;
          }

          .cutting-tip {
            align-self: center;
          }
        }
      }
    }
    /deep/.add-standard-new {
      .new-button_text {
        top: -54px;
        right: -24px;
      }
    }
  }

  /deep/.el-drawer__header {
    margin-bottom: 0;
    padding: 0;
  }

  .drawer-main {
    position: relative;
    width: 100%;
    height: calc(100vh - 50px);
  }

  .drawer-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 24px;
    color: #000;
    font-weight: 500;
    font-size: 18px;
    font-family: PingFangSC-Medium, 'PingFang SC';
    border-color: rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid;
  }

  .new-plank-form {
    box-sizing: border-box;
    padding: 24px;

    .new-plank-form-item {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      /deep/.el-input {
        width: 368px;

        .el-input__inner {
          height: 32px;
        }
      }

      .error-info {
        position: absolute;
        top: 32px;
        left: 84px;
        color: #fd3232;
        font-size: 12px;
      }

      .plank-form-title {
        display: inline-block;
        flex-shrink: 0;
        width: 82px;
        color: #666;
        font-size: 14px;
        text-align: right;
        cursor: default;
      }

      .plank-form-input {
        width: 368px;

        /deep/ .absolute {
          position: absolute !important;
          top: 0;
          left: 0;
          z-index: 9999;
        }

        .plank-form-select.ant-select-dropdown {
          z-index: 3000;
        }

        /deep/ .el-select {
          display: block;

          .is-focus {
            .el-input__inner {
              border-color: #18a8c7;

              &:focus {
                border-color: #18a8c7;
              }
            }
          }

          .el-input__inner {
            &:focus {
              border-color: #18a8c7;
            }
          }
        }

        /deep/.el-cascader {
          display: block;

          .el-input {
            .el-input__inner:focus,
            .el-cascader .el-input.is-focus .el-input__inner {
              border-color: #18a8c7;
            }
          }
        }
      }

      .colorInpWrap {
        .error-info {
          position: absolute;
          top: 32px;
          left: 0;
          color: #fd3232;
          font-size: 12px;
        }
      }

      .plank-form-limit {
        color: #b0b0b0;
        font-size: 14px;
        cursor: default;
        user-select: none;
      }

      .plank-form-after {
        color: #b0b0b0;
        font-size: 14px;
        cursor: default;
        user-select: none;
      }

      .important-icon {
        margin-right: 4px;
        color: red;
        font-size: 14px;
      }

      .ant-select {
        width: 368px;
      }
    }
  }

  .drawer-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    padding: 0 56px 0 68px;
    background: rgba(216, 216, 216, 0);
    border-color: rgba(0, 0, 0, 0.06);
    border-top: 1px solid;
    box-shadow: 0 1px 0 0 rgb(229, 229, 229);

    span {
      display: inline-block;
      width: 120px;
      height: 32px;
      line-height: 32px;
      text-align: center;
    }

    .drawer-bottom-btn {
      box-sizing: border-box;
      color: #333;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 2px;
      cursor: pointer;
    }

    .confirm-btn {
      color: #fff;
      background: #18a8c7;
      border: none;
    }
  }

  .delete-dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .delete-dialog-content {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 424px;
      height: 200px;
      margin: auto;
      padding: 32px 32px 24px;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0 12px 48px 16px rgba(0, 0, 0, 0.03),
        0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 6px 16px -8px rgba(0, 0, 0, 0.08);

      .delete-title {
        display: flex;
        align-items: center;
        height: 22px;
        margin-bottom: 13px;
      }

      .icon-warn {
        margin-right: 17px;
        color: #faad14;
        font-size: 22px;
      }

      .warm-notice {
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
      }

      .confirm-notice {
        margin-bottom: 24px;
        padding-left: 40px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }

      .delete-btns {
        display: flex;
        justify-content: flex-end;

        > div {
          .ant-btn {
            width: 85px;
            height: 32px;
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
          }

          .confirm-btn {
            color: #fff;
            background: #18a8c7;
            border: none;
          }
        }
      }
    }
  }

  .alert-input {
    /deep/.ant-select-selection {
      border-color: #fd3232;
    }

    /deep/.ant-input {
      border-color: #fd3232;
    }

    &.ant-input {
      border-color: #fd3232;
    }
  }

  .errorInfo-enter-active,
  .errorInfo-leave-active {
    transition: all 0.3s ease;
  }

  .errorInfo-enter,
  .errorInfo-leave-to {
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
  }

  .errorInfo-enter-to,
  .errorInfo-leave {
    transform: scaleY(1);
    transform-origin: top;
  }
}

.blank-table /deep/ .cell {
  height: 40px !important;
  line-height: 40px !important;
}

/deep/ .absolute,
.el-input {
  width: 100% !important;
}
</style>
<style>
.plank-form-select.ant-select-dropdown {
  z-index: 3000;
}

.el-select-dropdown__item.selected {
  color: #18a8c7;
}

.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  color: #18a8c7;
}
</style>
